@use 'mixins';

.TabbedContent {
    font-family: var(--textFont);
    background-color: unset;

    // Remove border-top when nesting
    .TabbedContent ul.tabs {
        border-top: none;
    }

    > header {
        padding-left: var(--standardPadding);
        display: flex;
        flex-direction: row;

        :global(.theme-vi) & {
            .tabbed-content-title {
                display: none;
            }
        }

        .DropdownContainer {
            margin-left: auto;
            align-self: flex-end;
        }

        h2 {
            width: fit-content;
            padding-bottom: var(--standardPadding);
            font-style: normal;

            :global(.theme-vp) &,
            :global(.theme-vpbe) &,
            :global(.theme-vn) & {
                border-bottom: 2px solid rgb(var(--fourthColor));
            }

            :global(.theme-gp) & {
                border-bottom: 3px solid rgb(var(--mainColor));
            }
        }

        a {
            align-self: flex-end;
            flex: 1 1;
            font-size: 1.1em;
            font-weight: 700;
            line-height: 1.2em;
            padding-bottom: var(--standardPadding);
            text-align: right;
            text-decoration: none;

            &:hover {
                cursor: pointer;
            }
        }
    }

    ul.tabs {
        display: flex;
        padding: 0 var(--standardPadding);
        background: rgb(var(--sectionBack));
        color: rgb(var(--sectionTextColor));
        font-weight: 700;
        text-align: center;
        user-select: none;
        border-top: 1px solid rgb(var(--sectionBorder));
        border-bottom: 1px solid rgb(var(--sectionBorder));

        :global(.theme-vp) &,
        :global(.theme-vpbe) &,
        :global(.theme-vn) & {
            padding: var(--tinyPadding) var(--standardPadding);
        }

        * {
            outline: none;
        }

        li {
            flex: 1;
            padding: var(--standardPadding) 0;
            border-radius: var(--standardRadius);
            height: unset;
            line-height: 1.5em;
            font-size: 1em;
            text-transform: uppercase;
            color: rgb(var(--lighterTextColor));
            cursor: pointer;

            :global(.theme-vp) &,
            :global(.theme-vpbe) &,
            :global(.theme-vn) & {
                padding: unset;
                height: 2em;
                line-height: 2em;
            }

            &.active {
                @extend %tab-shadow;

                background: rgb(var(--background));
                color: rgb(var(--textColor));
                border-bottom: 3px solid rgb(var(--mainColor));

                :global(.theme-vp) &,
                :global(.theme-vpbe) &,
                :global(.theme-vn) & {
                    box-shadow: none;
                    background-color: transparent;
                    color: rgb(var(--mainColor));
                }
            }

            &:hover {
                color: rgb(var(--mainColor));
            }
        }

        &:global(.inverted) {
            background-color: unset;
            color: rgb(var(--invertedTextColor));

            :global(.theme-vp) &,
            :global(.theme-vpbe) & {
                background-color: rgb(var(--textColor));
            }

            li {
                opacity: 0.6;
                transition: opacity 0.1s ease-out;
                transition-delay: 0.05s;

                &:hover {
                    color: rgb(var(--mainColor));
                    opacity: 1;
                    transition: opacity 0.15s ease-in;
                }

                // Active
                &.active {
                    background-color: transparent;
                    color: rgb(var(--textColor));
                    opacity: 1;
                    box-shadow: none;
                    transition: opacity 0.15s ease-in;

                    :global(.theme-vp) &,
                    :global(.theme-vpbe) &,
                    :global(.theme-vn) & {
                        background-color: rgb(var(--background));
                        color: rgb(var(--mainColor));
                        box-shadow: inherit;
                        border-bottom: none;
                    }

                    :global(.theme-vi) & {
                        color: rgb(var(--mainColor));
                    }
                }

                // Active & Hovered
                &.active:hover {
                    color: rgb(var(--mainColor));
                    cursor: default;
                }

                :global(.theme-vi) & {
                    opacity: unset;
                }
            }
        }

        &.Slider {
            padding: var(--tinyPadding) 0;
            flex-direction: row;
            align-items: center;

            > li {
                display: none;

                &.active {
                    display: unset;
                    width: 100%;
                    border-bottom: none;
                }

                &:hover {
                    color: rgb(var(--textColor));
                    cursor: default;
                }
            }

            svg {
                display: unset;
            }

            path {
                fill: rgb(var(--lighterTextColor));
            }

            > div {
                display: block;
                padding: 0 var(--tinyPadding);

                &.hidden {
                    visibility: hidden;

                    :hover {
                        cursor: default;
                    }
                }

                &:hover {
                    cursor: pointer;
                }
            }
        }

        svg {
            display: none;
        }

        > div {
            display: none;
        }
    }

    .tab-wrapper {
        display: inherit;
    }

    &.primary {
        margin-top: 0;

        ul.tabs {
            margin-top: 0;
            margin-bottom: var(--largePadding);

            :global(.theme-vi) &,
            :global(.theme-vp) &,
            :global(.theme-vpbe) &,
            :global(.theme-vn) & {
                background-color: rgb(var(--invertedBackground));
                padding: var(--tinyPadding) var(--standardPadding);
                border: none;
            }

            li {
                line-height: 2.25em;
                height: 2.25em;

                &:hover {
                    :global(.theme-vp) &,
                    :global(.theme-vpbe) &,
                    :global(.theme-vn) & {
                        color: rgb(var(--invertedTextColor));
                    }
                }

                &.active:hover {
                    color: initial;
                }
            }

            :global(.theme-vi) & {
                width: 100%;
                padding: 0;
                background-color: rgb(var(--lighterInvertedBackground));
                border-top: 1px solid rgb(var(--secondaryTextColor));
                border-radius: 0 0 var(--standardRadius) var(--standardRadius);
                overflow-x: hidden;

                li {
                    padding: var(--tinyPadding);
                    text-transform: capitalize;
                    font-weight: 600;
                    border-radius: unset;

                    &.active {
                        color: rgb(var(--mainColor));
                    }
                }
            }
        }
    }

    &.vertical {
        ul.tabs {
            flex-direction: column;
            align-self: flex-start;
            background: rgb(var(--background));
            padding: 0;
            text-align: left;

            li {
                padding: var(--standardPadding);
                border-bottom: 1px solid rgb(var(--sectionBorder));
                opacity: 0.4;

                &.active {
                    opacity: 1;
                    box-shadow: none;
                }
            }

            .f-icon {
                margin-right: var(--smallPadding);
            }
        }
    }

    @include mixins.responsive(m, below) {
        :global(.theme-vi) & {
            &.primary ul.tabs {
                border-radius: 0;
            }

            ul.tabs {
                margin-bottom: 0;
            }
        }

        &.vertical {
            ul.tabs {
                border: 1px solid rgb(var(--sectionBorder));

                li {
                    padding: var(--standardPadding);
                    border-bottom: 0;
                    border-top: 1px solid rgb(var(--sectionBorder));
                }
            }
        }
    }
}
