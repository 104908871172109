.YoutubeVideo {
    width: 100%;
    height: 100%;
    aspect-ratio: 16/9;
    max-width: 900px;
}

.YoutubeShort {
    width: 315px;
    height: 560px;
}
