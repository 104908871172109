@use 'mixins';

.Iframe {
    display: block;
    position: relative;

    &.loading {
        background-color: rgb(var(--lightSectionBack));
    }

    &.loaded {
        background-color: transparent;
        transition: background-color 0.25s ease-in-out;
    }

    &:has(.iframe-default, .iframe-apple, .iframe-google, .iframe-spotify) {
        aspect-ratio: auto;
    }

    &:has(.iframe-24liveblog) {
        aspect-ratio: 3 / 4;
    }

    &:has(.iframe-youtube, .iframe-dailymotion, .iframe-kijk) {
        aspect-ratio: 16 / 9;
    }

    &:has(.iframe-art19) {
        aspect-ratio: 6 / 5;
    }

    &:has(.iframe-service) {
        aspect-ratio: 1 / 3;
    }

    @include mixins.responsive(xs, above) {
        .iframe-art19 {
            aspect-ratio: 3 / 2;
        }
    }

    @include mixins.responsive(s, above) {
        &:has(.iframe-service) {
            aspect-ratio: 1 / 2;
        }
    }

    @include mixins.responsive(m, above) {
        .iframe-24liveblog {
            aspect-ratio: 1 / 1;
        }

        .iframe-art19 {
            aspect-ratio: 3 / 1;
        }

        .iframe-dailymotion,
        .iframe-youtube {
            aspect-ratio: 16 / 9;
        }

        &:has(.iframe-service) {
            aspect-ratio: 2 / 3;
        }
    }

    @include mixins.responsive(l, above) {
        &:has(.iframe-service) {
            aspect-ratio: 9 / 10;
        }
    }
}
